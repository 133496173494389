export default {
  colors: {
    primary: "#732937",
    primaryDark: "#5C021E",
    secondary: "#d54d67",
    secondaryDark: "#69002A",
    text: "#0D0D0D",
    textDark: "#000",
    navLink: "#5C021E",
    navLinkScroll: "#ECE9F2",
    bgHeader: "rgba(0, 0, 0, 0.4)",
    bgHeaderDark: "#732937",
    body: "#ECE9F2",
    titlePage: "#fff",
  },
};
