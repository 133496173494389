import React from "react";

import { ThemeProvider } from "styled-components";

import theme from "./theme/default";
// import Layout from "../src/components/layout";

export function wrapRootElement({ element }) {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
}
