// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acupuntura-js": () => import("./../../../src/pages/acupuntura.js" /* webpackChunkName: "component---src-pages-acupuntura-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infertilidade-js": () => import("./../../../src/pages/infertilidade.js" /* webpackChunkName: "component---src-pages-infertilidade-js" */),
  "component---src-pages-livros-dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa-js": () => import("./../../../src/pages/livros/dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa.js" /* webpackChunkName: "component---src-pages-livros-dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa-js" */),
  "component---src-pages-livros-psique-e-medicina-tradicional-chinesa-js": () => import("./../../../src/pages/livros/psique-e-medicina-tradicional-chinesa.js" /* webpackChunkName: "component---src-pages-livros-psique-e-medicina-tradicional-chinesa-js" */),
  "component---src-pages-medicina-integrativa-js": () => import("./../../../src/pages/medicina-integrativa.js" /* webpackChunkName: "component---src-pages-medicina-integrativa-js" */),
  "component---src-pages-medicina-tradicional-chinesa-js": () => import("./../../../src/pages/medicina-tradicional-chinesa.js" /* webpackChunkName: "component---src-pages-medicina-tradicional-chinesa-js" */),
  "component---src-pages-meditacao-js": () => import("./../../../src/pages/meditacao.js" /* webpackChunkName: "component---src-pages-meditacao-js" */),
  "component---src-pages-mente-e-corpo-js": () => import("./../../../src/pages/mente-e-corpo.js" /* webpackChunkName: "component---src-pages-mente-e-corpo-js" */),
  "component---src-pages-muito-obrigada-js": () => import("./../../../src/pages/muito-obrigada.js" /* webpackChunkName: "component---src-pages-muito-obrigada-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-telemedicina-js": () => import("./../../../src/pages/telemedicina.js" /* webpackChunkName: "component---src-pages-telemedicina-js" */)
}

